import SceneController from "@/classes/SceneController.class"
import {AssetContainer, Color3, Mesh, PBRMaterial, Vector3} from "@babylonjs/core"
import ModelController from "@/classes/SceneController/ModelController.class"

export enum LetterModelEvents {
  AnimationComplete = 'AnimationComplete'
}

export default class LetterModel extends ModelController {
  private _parentMesh: Mesh
  private _visible = false
  private _animationsStarted = false
  private _topLetterLayerVisible = false

  private _updateDisabled = true

  get visible() : boolean {
    return this._visible
  }

  set visible(val: boolean) {
    if (this._visible !== val) {
      this._visible = val

      if (this._visible) this._updateDisabled = false

      if (this.mesh) this.mesh.scaling = this._visible ? Vector3.One().multiplyByFloats(1, 1, 1) : Vector3.Zero()

      if (this._visible) {
        setTimeout(() => {
          this._animationsStarted = true
          this.assetContainer.animationGroups.forEach((animationGroupItem) => {
            animationGroupItem.start(false, 1.3, 0)
          })
        }, 9000)

        setTimeout(() => {
          this._topLetterLayerVisible = true
        }, 4000)
      } else {
        this._animationsStarted = false
        this.assetContainer.animationGroups.forEach((animationGroupItem) => {
          animationGroupItem.stop()
        })
      }
    }
  }

  constructor({sceneController, parentMesh} : {sceneController:SceneController, parentMesh:Mesh}) {
    super({sceneController})

    this._parentMesh = parentMesh
    this._loadModel()

    this.scene.onBeforeRenderObservable.add(this._update)
  }

  private _update = () : void => {
    if (!this._updateDisabled) {
      const deltaTime = this.sceneController.engine.getDeltaTime()

      if (this.mesh) {
        if (this._visible) {

          this.assetContainer.materials.forEach((material) => {
            if (material.name === 'Material.007') {
              if (this._topLetterLayerVisible || this._animationsStarted) {
                material.alpha += ((0 - material.alpha) / 2000) * deltaTime
              } else {
                material.alpha += ((1. - material.alpha) / 600) * deltaTime
                if (material.alpha > 0.99) material.alpha = 1
              }

            } else if (material.name === 'Material.006') {

              if (this._topLetterLayerVisible || this._animationsStarted) {
                material.alpha += ((1 - material.alpha) / 3000) * deltaTime
              } else {
                material.alpha += ((0 - material.alpha) / 1000) * deltaTime
              }

            } else {

              if (this._animationsStarted) {
                material.alpha += ((1 - material.alpha) / 1000) * deltaTime
              } else {
                material.alpha += ((0 - material.alpha) / 1000) * deltaTime
              }

            }
          })

        } else {
          let complete = true
          this.assetContainer.materials.forEach((material) => {
            material.alpha += ((0 - material.alpha) / 1000) * deltaTime

            if (material.alpha > 0.01) {
              complete = false
            }
          })

          if (complete) {
            this.assetContainer.materials.forEach((material) => {
              material.alpha = 0
            })
            this._updateDisabled = true
          }
        }
      }
    }
  }

  private _onAnimationComplete = () : void => {
    setTimeout(() => {
      if (this.visible) this.dispatchEvent(new Event(LetterModelEvents.AnimationComplete))
    }, 3000)
  }

  private _loadModel() : void {
    this.loadModelFromUrl('/models/letters-8.glb').then((assetContainer) => {

      assetContainer.animationGroups.forEach((animationGroupItem) => {
        animationGroupItem.stop()
        if (animationGroupItem.name === 'ArmatureAction') {
          animationGroupItem.onAnimationGroupEndObservable.add(this._onAnimationComplete)
        }
      })

      assetContainer.materials.forEach((materialItem) => {
        const material:PBRMaterial = materialItem as PBRMaterial
        material.alpha = 0
        if (material.name === 'Material.004') {
          material.emissiveColor = Color3.White()
          material.emissiveIntensity = 0.5
        }

        // if (material.name === 'Material.007') {
        //   material.disableLighting = true
        // }

        // if (material.name === 'Material.006') {
        //   material.disableLighting = true
        //   material.emissiveColor = Color3.White()
        //   material.emissiveIntensity = 0.01
        // }
      })

      this.mesh!.parent = this._parentMesh
      this.mesh!.scaling = Vector3.Zero()
      this.mesh!.position = new Vector3(0, -2, 0)
    })
  }
}
