

import {Options, Vue} from "vue-class-component"
import ArPictureSceneController, {ArPictureSceneControllerEvents} from "@/components/ArPicture/classes/ArPictureSceneController.class"
import {PictureAnimations} from "@/components/ArPicture/classes/PictureModel.class"
import ProgressBar from "@/components/ArPicture/ProgressBar.vue"
import FinalScreen from "@/components/ArPicture/FinalScreen.vue"

@Options({
  components: {ProgressBar, FinalScreen}
})
export default class ArPicture extends Vue {
  public pictureLoaded = false
  public setupButtonPressed = false
  public pictureAnimationComplete = false
  public preloaderVisible = false

  private _sceneController!:ArPictureSceneController

  public currentAnimationIndex = 0

  public setupText = 'ДЛЯ УСТАНОВКИ AR ВЫБЕРИТЕ ПРОСТОРНУЮ,<br/>РОВНУЮ И ГОРИЗОНТАЛЬНУЮ ПОВЕРХНОСТЬ.<br/>ТАКЖЕ РЕКОМЕНДУЕМ ВКЛЮЧИТЬ ЗВУК НА<br/> ВАШЕМ УСТРОЙСТВЕ'

  private animationsList: PictureAnimations[] = [
    PictureAnimations.Start,
    PictureAnimations.Frames,
    PictureAnimations.Letter,
    PictureAnimations.Details,
    PictureAnimations.Smears,
    PictureAnimations.Final,
  ]

  public get allAnimationsComplete() : boolean {
    return this.currentAnimationIndex === this.animationsList.length - 1
  }

  mounted() : void {
    // console.log('v 2.0')

    this._sceneController = new ArPictureSceneController({
      canvas: this.$refs.canvas as HTMLCanvasElement
    })

    this._sceneController.addEventListener(ArPictureSceneControllerEvents.PictureModelLoaded, this._onModelLoaded)
    this._sceneController.addEventListener(ArPictureSceneControllerEvents.PictureAnimationComplete, this._onPictureAnimationComplete)
    this._sceneController.addEventListener(ArPictureSceneControllerEvents.SoundStarted, this._onSoundStarted)
  }

  private _onSoundStarted() : void {
    this.preloaderVisible = false
  }

  private _onPictureAnimationComplete() : void {
    this.pictureAnimationComplete = true

    // this.currentAnimationIndex = 5
    // this._sceneController.playAnimation(this.animationsList[this.currentAnimationIndex])
    // this._sceneController.playFrameSound(this.animationsList[this.currentAnimationIndex])
  }

  private _onModelLoaded() : void {
    this.pictureLoaded = true
  }

  public onSetupButtonClick() : void {
    this._sceneController.setupPicture()
    this.setupButtonPressed = true
    this.preloaderVisible = true
  }

  public onContinueButtonClick() : void {
    this.pictureAnimationComplete = false

    this.currentAnimationIndex++
    if (this.currentAnimationIndex >= this.animationsList.length) this.currentAnimationIndex = 1

    this._sceneController.playAnimation(this.animationsList[this.currentAnimationIndex])
    this._sceneController.playFrameSound(this.animationsList[this.currentAnimationIndex])
  }
}
