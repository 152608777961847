import SceneController from "@/classes/SceneController.class";
import * as BABYLON from '@babylonjs/core'
import GroundModel from "@/components/ArPicture/classes/GroundModel.class";
import PictureModel, {PictureAnimations, PictureModelEvents} from "@/components/ArPicture/classes/PictureModel.class";
import {
  // HardwareScalingOptimization,
  // MergeMeshesOptimization,
  // TextureOptimization,
  SceneOptimizer,
  SceneOptimizerOptions,
  Vector3
} from "@babylonjs/core";
import ArPlaceMarkerModel from "@/components/ArPicture/classes/ArPlaceMarkerModel.class";
import CameraLightsController from "@/components/ArPicture/classes/CameraLightsController.class";
import ApplicationConfig from "@/classes/ApplicationConfig";

export enum ArPictureSceneControllerEvents {
  PictureModelLoaded = 'PictureModelLoaded',
  PictureAnimationComplete = 'PictureAnimationComplete',
  SoundStarted = 'SoundStarted'
}

export enum WebXRDriver {
  EightWall = 'EightWall',
  BabylonWebXR = 'BabylonWebXR'
}

export default class ArPictureSceneController extends SceneController {
  private _webXRDriver : WebXRDriver
  private _groundModel!: GroundModel
  private _pictureModel!: PictureModel
  private _arPlaceMarkerModel!: ArPlaceMarkerModel

  private _lightsController!: CameraLightsController

  private _sceneOptimizerOptions!: SceneOptimizerOptions
  private _sceneOptimizer!: SceneOptimizer

  constructor({canvas}: {canvas: HTMLCanvasElement}) {
    super({canvas})
    this._webXRDriver = WebXRDriver.EightWall
    this._initDriver()

    //this._createOptimizer()
  }

  // private _createOptimizer() : void {
  //   this._sceneOptimizerOptions = new SceneOptimizerOptions()
  //   this._sceneOptimizerOptions.addOptimization(new HardwareScalingOptimization(1., 2.))
  //   // this._sceneOptimizerOptions.addOptimization(new MergeMeshesOptimization(0))
  //   // this._sceneOptimizerOptions.addOptimization(new TextureOptimization(0, 512))
  //
  //   this._sceneOptimizer = new SceneOptimizer(this.scene, this._sceneOptimizerOptions)
  //
  //   this._sceneOptimizer.onNewOptimizationAppliedObservable.add((e) => {
  //     console.log('OPTIMIZATION!', e)
  //   })
  //
  //   this._sceneOptimizer.targetFrameRate = 25
  // }
  //
  // public startOptimization() : void {
  //   this._sceneOptimizer.start()
  // }

  private _initDriver() : void {
    if (ApplicationConfig.webxrEnabled) {
      if (this._webXRDriver === WebXRDriver.EightWall) {
        (window as any)['BABYLON'] = BABYLON

        const XRExtras: any = (window as any)['XRExtras']
        const windowIframeInnerPipelineModule: any = (window as any)['iframeInnerPipelineModule']
        const onxrloaded = () => {
          const XR8: any = (window as any)['XR8']

          XR8.addCameraPipelineModules([             // Add camera pipeline modules.
            XRExtras.AlmostThere.pipelineModule(),   // Detects unsupported browsers and gives hints.
            XRExtras.Loading.pipelineModule(),       // Manages the loading screen on startup.
            XRExtras.RuntimeError.pipelineModule(),  // Shows an error image on runtime error.
            windowIframeInnerPipelineModule
          ])

          this._startEightWallScene()
        }

        XRExtras.Loading.showLoading({onxrloaded})

        const loadImageContainer = document.querySelector('#loadImageContainer')
        if (loadImageContainer) {
          const logoEl = document.createElement('div')
          logoEl.className = 'logo'
          loadImageContainer.before(logoEl)
        }

        const cameraRequestElement = document.getElementById('requestingCameraPermissions')

        if (cameraRequestElement) cameraRequestElement.innerHTML = '' +
          '<div class="logo"></div>'

        const interval = setInterval(() => {
          const sensorsRequestElement = document.querySelector('.prompt-box-8w')
          if (sensorsRequestElement) {
            const p = document.querySelector('.prompt-box-8w p')
            const cancelButton = document.querySelector('.prompt-box-8w .prompt-button-8w')
            const continueButton = document.querySelector('.prompt-box-8w .prompt-button-8w.button-primary-8w')

            if (p && cancelButton && continueButton) {
              const logoEl = document.createElement('div')
              logoEl.className = 'logo'
              p.before(logoEl)
              p.innerHTML = '<span>РАЗРЕШИТЕ ДОСТУП К<br/>ДАТЧИКАМ ДВИЖЕНИЯ<br/>И КАМЕРЕ</span><span class="lg-partner">LG SIGNATURE ЯВЛЯЕТСЯ ПАРТНЕРОМ ГМИИ<br/>ИМ. А.С. ПУШКИНА В ПРОЕКТЕ<br/>ПО ИССЛЕДОВАНИЮ И РЕСТАВРАЦИИ<br/>КАРТИНЫ ВАН ГОГА<br/>“КРАСНЫЕ ВИНОГРАДНИКИ В АРЛЕ.<br/>МОНМАЖУР”</span>'
              cancelButton.innerHTML = 'Отмена'
              continueButton.innerHTML = 'ПРОДОЛЖИТЬ'
              clearInterval(interval)
            }
          }
        }, 10)
      }
    } else {
      this._startEightWallScene()
    }
  }

  private _startEightWallScene() : void {
    this.initEngine()
    this.createCameraController()
    this.cameraController.camera.position = new Vector3(0, 3, -5)

    this._initModels()
  }

  private _initModels() : void {
    this._lightsController =  new CameraLightsController({
      sceneController: this
    })

    this._groundModel = new GroundModel({
      sceneController: this
    })

    this._arPlaceMarkerModel = new ArPlaceMarkerModel({
      sceneController: this
    })

    this._pictureModel = new PictureModel({
      sceneController: this
    })

    this._pictureModel.addEventListener(PictureModelEvents.Loaded, this._onPictureModelLoaded)
    this._pictureModel.addEventListener(PictureModelEvents.AnimationComplete, this._onPictureAnimationComplete)
    this._pictureModel.addEventListener(PictureModelEvents.SoundStarted, this._onSoundStarted)
  }

  private _onSoundStarted = () : void => {
    this.dispatchEvent(new Event(ArPictureSceneControllerEvents.SoundStarted))
  }

  private _onPictureModelLoaded = () : void => {
    this.dispatchEvent(new Event(ArPictureSceneControllerEvents.PictureModelLoaded))
  }

  private _onPictureAnimationComplete = () : void => {
    this.dispatchEvent(new Event(ArPictureSceneControllerEvents.PictureAnimationComplete))
  }

  public setupPicture() : void {
    this._pictureModel.setupOnSurface()
    this._arPlaceMarkerModel.setupOnSurface()
  }

  public playAnimation(animation: PictureAnimations): void {
    // console.log('playAnimation', animation)
    this._pictureModel.playAnimation(animation)
  }

  public playFrameSound(animation: PictureAnimations) : void {
    this._pictureModel.playFrameSound(animation)
  }
}
