import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class"
import {HemisphericLight, Vector3} from "@babylonjs/core";
// import {Light} from "@babylonjs/core";

export default class CameraLightsController extends SceneItem {
  // private _lightsList: Light[] = []
  private _hemiLight!: HemisphericLight


  constructor(props: SceneItemArgs) {
    super(props)

    this._createLights()
  }

  private _createLights() : void {
    this._hemiLight = new HemisphericLight('hemi light', Vector3.Up(), this.scene)
    this._hemiLight.intensity = 1
    // this._lightsList.push()
  }
}
