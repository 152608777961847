import ModelController from "@/classes/SceneController/ModelController.class"
import {SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class"
import {
  AnimationGroup, Color3, Engine,
  GlowLayer, Material,
  Mesh,
  Nullable,
  PBRMaterial,
  PickingInfo, Texture, TransformNode,
  Vector3
} from "@babylonjs/core"
import FlyingFrames, {FlyingFramesEvents} from "@/components/ArPicture/classes/PictureModel/FlyingFrames.class"
import PictureDetails from "@/components/ArPicture/classes/PictureModel/PictureDetails.class"
import LetterModel, {LetterModelEvents} from "@/components/ArPicture/classes/PictureModel/LetterModel.class"
import SoundPlayer from "@/components/ArPicture/classes/SoundPlayer.class"
import ArPictureSceneController from "@/components/ArPicture/classes/ArPictureSceneController.class";

export enum PictureModelEvents {
  Loaded= 'Loaded',
  PictureModelLoaded = 'PictureModelLoaded',
  AnimationComplete = 'AnimationComplete',
  SoundStarted = 'SoundStarted'
}

export enum PictureAnimations {
  Start = 'Start',
  Frames = 'Frames',
  Letter = 'Letter',
  Details = 'Details',
  Smears = 'Smears',
  Final = 'Final',
}

export default class PictureModel extends ModelController {
  private _animation!: PictureAnimations
  private _completedAnimation!: PictureAnimations

  private _isSetuped = false
  private _time = 0

  private _pictureMaterial!: PBRMaterial
  private _pictureScreenMaterial!: PBRMaterial
  private _screenMaterial!: PBRMaterial

  private _gridAnimationsGroups: AnimationGroup[] = []
  private _gridAnimationStarted = false
  private _gridAnimationComplete = false

  private _frameAnimationsGroups: AnimationGroup[] = []
  private _frameInitAnimationStarted = false
  private _frameTransformAnimationStarted = false
  private _smearsTimeoutStarted = false

  private _pictureMesh!: Mesh
  private _cityTexture!: Texture
  private _mainPictureTexture!: Texture
  private _cityTextureApplied = false
  private _framesShowCity = false

  private _flyingFrames!: FlyingFrames
  private _pictureDetails!: PictureDetails
  private _letterModel!: LetterModel

  private _soundPlayer: SoundPlayer

  private _detailsAnimationComplete = false
  private _smearsAnimationComplete = false

  private _prizmesList: Mesh[] = []
  private _prizmesRotationAmount: number[] = []
  private _prizmesPositions: Vector3[] = []

  constructor(args: SceneItemArgs) {
    super(args)
    this._loadModel()

    this._soundPlayer = new SoundPlayer({
      sceneController: this.sceneController
    })

    this._cityTexture = new Texture('/models/textures/city-texture.png', this.scene)
    this._cityTexture.vScale = -1
    this._cityTexture.hasAlpha = true

    this.scene.onBeforeRenderObservable.add(this._update)
  }

  private _onGridAnimationComplete = () : void => {
    this._gridAnimationComplete = true
  }

  private _onFlyFramesAnimationComplete = () : void => {
    if (this._animation === PictureAnimations.Frames) this._completeAnimation()
  }

  private _onLetterAnimationComplete = () : void => {
    if (this._animation === PictureAnimations.Letter) this._completeAnimation()
  }

  private _update = () : void => {
    this._time += this.sceneController.engine.getDeltaTime() / 1000

    if (this.mesh && !this._isSetuped) {
      const x: number = window.innerWidth / 2
      const y: number = window.innerHeight - window.innerHeight / 2

      const pickedInfo: Nullable<PickingInfo> = this.scene.pick(x, y, (mesh) => mesh.isPickable)
      if (pickedInfo?.pickedMesh && pickedInfo?.pickedMesh.name === 'ground'  && pickedInfo?.pickedPoint) {
        this.mesh.position = pickedInfo.pickedPoint.clone()
        this.mesh.position.y = 0
        this.mesh.lookAt(this.camera.position)

        this.mesh.rotation.z = 0
        this.mesh.rotation.x = 0
      }
    }

    if (this._gridAnimationComplete) {
      this._prizmesList.forEach((prizmMesh, index) => {
        prizmMesh.rotate(Vector3.Right(), this._prizmesRotationAmount[index])
      })
    }

    if (this.mesh) {
      const scaling : Vector3 = this._isSetuped ? Vector3.One() : Vector3.Zero()
      // scaling.x *= 0.7
      // scaling.y *= 0.7
      // scaling.z *= 0.7

      this.mesh.scaling = scaling

      if (this._isSetuped) {
        if ([PictureAnimations.Smears, PictureAnimations.Details].indexOf(this._animation) < 0) {
          this._pictureDetails.visible = false
        }

        if (this._animation !== PictureAnimations.Smears && this._smearsTimeoutStarted) {
          this._smearsTimeoutStarted = false
        }

        switch (this._animation) {
          case PictureAnimations.Start:
            this._startAnimationUpdate()
            break

          case PictureAnimations.Frames:
            this._framesAnimationUpdate()
            break

          case PictureAnimations.Letter:
            this._letterAnimationUpdate()
            break

          case PictureAnimations.Details:
            this._detailsAnimationUpdate()
            break

          case PictureAnimations.Smears:
            this._smearsAnimationUpdate()
            break
        }
      }
    }
  }

  private isAnimationComplete(animation:PictureAnimations) : boolean {
    return this._completedAnimation === animation
  }

  private _completeAnimation() : void {
    if (this._completedAnimation !== this._animation) {
      this._completedAnimation = this._animation
      this.dispatchEvent(new Event(PictureModelEvents.AnimationComplete))
    }
  }

  private _smearsAnimationUpdate() : void {
    const deltaTime = this.sceneController.engine.getDeltaTime()
    this._pictureMesh.scaling = Vector3.One()

    this._letterModel.visible = false

    this._pictureMesh.getChildTransformNodes().forEach((node) => {
      if (node.name === 'Frame_appearence_animation') {
        node.scaling = Vector3.Zero()
      }

      if (node.name === 'Frame_transform_animation') {
        node.scaling = Vector3.One()
      }
    })

    this._pictureMaterial.alpha += ((1 - this._pictureMaterial.alpha) / 1000) * deltaTime
    this._pictureScreenMaterial.alpha += ((1 - this._pictureScreenMaterial.alpha) / 1000) * deltaTime

    if (this._pictureMaterial.albedoTexture !== this._mainPictureTexture) {
      this._pictureMaterial.albedoTexture = this._mainPictureTexture
    }

    // if (this._pictureDetails.currentDetailIndex < 0) {
    //   if (!this._smearsAnimationComplete) {
    //     this._smearsAnimationComplete = true
    //   }
    // }

    if (!this.isAnimationComplete(PictureAnimations.Smears)) {
      if (!this._smearsTimeoutStarted) {
        this._smearsTimeoutStarted = true
        setTimeout(() => {
          this._completeAnimation()
        }, 93000)
        setTimeout(() => {
          this._pictureDetails.resume()
        }, 20000)
      }
    }
  }

  private _detailsAnimationUpdate() : void {
    const deltaTime = this.sceneController.engine.getDeltaTime()

    this._flyingFrames.visible = false
    this._letterModel.visible = false

    this._pictureMesh.scaling = Vector3.One()

    this._pictureMesh.getChildTransformNodes().forEach((node) => {
      if (node.name === 'Frame_appearence_animation') {
        node.scaling = Vector3.Zero()
      }

      if (node.name === 'Frame_transform_animation') {
        node.scaling = Vector3.One()
      }
    })

    if (this._pictureMaterial.albedoTexture !== this._mainPictureTexture) {
      this._pictureMaterial.albedoTexture = this._mainPictureTexture
    }

    this._pictureMaterial.alpha += ((1 - this._pictureMaterial.alpha) / 1000) * deltaTime
    if (this._pictureMaterial.alpha > 0.9) {
      this._pictureDetails.visible = true
    }

    this._screenMaterial.alpha += ((0 - this._screenMaterial.alpha) / 500) * deltaTime
    this._screenMaterial.specularIntensity = ((0 - this._screenMaterial.specularIntensity) / 1000) * deltaTime

    this._pictureScreenMaterial.alpha += ((1 - this._pictureScreenMaterial.alpha) / 1000) * deltaTime

    if (this._pictureDetails.currentDetailIndex >= 3 && !this._detailsAnimationComplete) {
      this._detailsAnimationComplete = true
      setTimeout(() => {
        this._completeAnimation()
        this._pictureDetails.pause()
      }, 14000)
    }
  }

  private _letterAnimationUpdate() : void {
    const deltaTime = this._sceneController.engine.getDeltaTime()

    this._flyingFrames.visible = false
    this._letterModel.visible = true

    this._pictureMesh.getChildTransformNodes().forEach((node) => {
      if (node.name === 'Frame_appearence_animation') {
        node.scaling = Vector3.Zero()
      }

      if (node.name === 'Frame_transform_animation') {
        node.scaling = Vector3.One()
      }
    })

    this._pictureMaterial.alpha += ((0 - this._pictureMaterial.alpha) / 1000) * deltaTime
    this._screenMaterial.alpha += ((0 - this._screenMaterial.alpha) / 500) * deltaTime
    this._screenMaterial.specularIntensity = ((0 - this._screenMaterial.specularIntensity) / 1000) * deltaTime
    this._pictureScreenMaterial.alpha += ((0 - this._pictureScreenMaterial.alpha) / 1000) * deltaTime

    //if (this._flyingFrames.animationComplete) {
    //  this._pictureMaterial.alpha += ((0 - this._pictureMaterial.alpha) / 1000) * deltaTime
    //  this._letterModel.visible = true
    //}
  }

  private _framesAnimationUpdate() : void {
    const deltaTime = this.sceneController.engine.getDeltaTime()
    this._pictureMesh.scaling = Vector3.One()
    this._pictureDetails.visible = false

    let hitechAnimationGroup: AnimationGroup
    let plaqueAnimationGroup: AnimationGroup

    this._letterModel.visible = false

    this._frameAnimationsGroups.forEach((frameAnimation) => {
      if (frameAnimation.name === 'Frame_transform_animationAction.001') {
        hitechAnimationGroup = frameAnimation
      } else if (frameAnimation.name === 'Plaque_animationAction') {
        plaqueAnimationGroup = frameAnimation
      }
    })

    this._pictureMesh.getChildTransformNodes().forEach((node) => {
      if (node.name === 'Frame_appearence_animation') {
        node.scaling = this._frameTransformAnimationStarted ? Vector3.Zero() : Vector3.One()
      }

      if (node.name === 'Frame_transform_animation') {
        node.scaling = this._frameTransformAnimationStarted ? Vector3.One() : Vector3.Zero()
      }
    })

    if (!this._frameTransformAnimationStarted) {
      this._frameTransformAnimationStarted = true
      hitechAnimationGroup!.start(false, 1, 0)
      plaqueAnimationGroup!.start(false, -1)

      setTimeout(() => {
        this._flyingFrames.visible = true
      }, 17000)
    }

    if (!this._cityTextureApplied) this._pictureMaterial.alpha += ((0 - this._pictureMaterial.alpha) / 1000) * deltaTime

    if (this._cityTextureApplied) {
      this._screenMaterial.alpha += ((0.6 - this._screenMaterial.alpha) / 1000) * deltaTime
      this._screenMaterial.specularIntensity += ((0.6 - this._screenMaterial.specularIntensity) / 1000) * deltaTime
    } else {
      this._screenMaterial.alpha += ((0 - this._screenMaterial.alpha) / 1000) * deltaTime
      this._screenMaterial.specularIntensity += ((0 - this._screenMaterial.specularIntensity) / 1000) * deltaTime
    }

    if (!this._cityTextureApplied) {
      this._pictureScreenMaterial.alpha += ((0 - this._pictureScreenMaterial.alpha) / 2000) * deltaTime
      if (this._pictureMaterial.alpha < 0.05) {
        this._pictureMaterial.alpha = 0
        this._pictureMaterial.albedoTexture = this._cityTexture
        this._pictureMaterial.useAlphaFromAlbedoTexture = true
        this._cityTextureApplied = true
        setTimeout(() => {
          this._framesShowCity = true
        }, 4500)
      }
    } else {
      if (!hitechAnimationGroup!.isPlaying) {
        if (!this._flyingFrames.expandAnimationComplete) {
          if (this._framesShowCity) this._pictureMaterial.alpha += ((1 - this._pictureMaterial.alpha) / 2000) * deltaTime
        } else {
          this._pictureMaterial.alpha += ((0 - this._pictureMaterial.alpha) / 2000) * deltaTime
        }
      }
    }
  }

  private _startAnimationUpdate() : void {
    const deltaTime = this.sceneController.engine.getDeltaTime()

    this._framesShowCity = false

    this._pictureMesh.getChildTransformNodes().forEach((node) => {
      if (node.name === 'Frame_appearence_animation') {
        node.scaling = Vector3.One()
      }
      if (node.name === 'Plaque_animation') {
        node.scaling = Vector3.One()
      }
      if (node.name === 'Screen') {
        node.scaling = Vector3.One()
      }
    })

    if (!this._gridAnimationStarted) {
      this._gridAnimationStarted = true
      this._gridAnimationsGroups.forEach((gridAnimationGroup) => {
        gridAnimationGroup.start(false, 0.4, 0)
        setTimeout(() => {
          this._completeAnimation()
        }, 59000)

        // gridAnimationGroup.start(false, 5, 0)
        // setTimeout(() => {
        //   this._completeAnimation()
        // }, 5000)
      })
    }

    this._prizmesList.forEach((prizmMesh, index) => {
      const toPosition: Vector3 = this._prizmesPositions[index]
      const k = this._sceneController.engine.getDeltaTime() * 0.0004
      const delta: Vector3 = toPosition.subtract(prizmMesh.position).multiplyByFloats(k, k, k)
      prizmMesh.position = prizmMesh.position.add(delta)
      prizmMesh.scaling = prizmMesh.scaling.add(Vector3.One().subtract(prizmMesh.scaling).multiplyByFloats(k,k,k))
    })

    if (this._gridAnimationComplete) {
      let fadeInAnimationGroup: AnimationGroup
      let plaqueAnimationGroup: AnimationGroup

      this._frameAnimationsGroups.forEach((frameAnimation) => {
        if (frameAnimation.name === 'Frame_transform_animationAction') {
          fadeInAnimationGroup = frameAnimation
        } else if (frameAnimation.name === 'Plaque_animationAction') {
          plaqueAnimationGroup = frameAnimation
        }
      })

      this._pictureScreenMaterial.alpha += ((1 - this._pictureScreenMaterial.alpha) / 3000) * deltaTime

      if (this._pictureScreenMaterial.alpha > 0.7) {
        this._pictureScreenMaterial.specularIntensity += ((1 - this._pictureScreenMaterial.specularIntensity) / 3000) * deltaTime

        if (!this._frameInitAnimationStarted) {
          this._frameInitAnimationStarted = true
          fadeInAnimationGroup!.start(false, .4, 0)
          plaqueAnimationGroup!.start(false, 1., 0)
          this._pictureMesh.scaling = Vector3.One()
        }

        if (!fadeInAnimationGroup!.isPlaying) {
          this._pictureMaterial.alpha += ((1 - this._pictureMaterial.alpha) / 1000) * deltaTime

          if (this._pictureMaterial.alpha > 0.9) {
            // this._completeAnimation()
          }
        }
      }
    }
  }


  private _loadModel() : void {
    this.loadModelFromUrl('/models/lg-model-16.glb').then((assetContainer) => {
      this._gridAnimationsGroups = assetContainer.animationGroups.filter(item => item.name === 'Armature.001Action.002')
      this._frameAnimationsGroups = assetContainer.animationGroups.filter(item => item.name !== 'Armature.001Action.002')

      this._gridAnimationsGroups.forEach((group) => {
        group.stop()
        group.onAnimationGroupEndObservable.add(this._onGridAnimationComplete)
      })

      this._frameAnimationsGroups.forEach((group) => {
        group.stop()
      })

      this.dispatchEvent(new Event(PictureModelEvents.Loaded))

      this._pictureMesh = new Mesh('Picture mesh', this.scene)

      assetContainer.materials.forEach((materialItem) => {
        const material: PBRMaterial = materialItem as PBRMaterial
        let freeze = true

        /** призмы **/
        if (material.name === 'glass_opaque.003') {
          // material.emissiveColor = Color3.FromHexString('#ffffff')
          // material.emissiveIntensity = 0.1
          material.specularIntensity = 1
          material.disableLighting = true
          material.environmentIntensity = 2
        }

        /** стекло **/
        if (material.name === 'glass_matt.003') {
          material.disableLighting = true
          material.environmentIntensity = 0
          // material.emissiveColor = Color3.FromHexString('#404040')
        }

        /** Грани **/
        if (material.name === 'glass_opaque_2.003') {
          material.emissiveColor = Color3.FromHexString('#aaccff')
          material.emissiveIntensity = 0.8
          material.disableLighting = true
          material.environmentIntensity = 2
        }

        /** рама картины **/
        if (material.name === 'glass_complex') {
          material.emissiveColor = Color3.FromHexString('#ffffff')
          material.emissiveIntensity = 0.2
          material.specularIntensity = 1
          // material.disableLighting = true
        }

        if (material.name === 'Paint') {
          this._pictureMaterial = material
          this._pictureMaterial.alpha = 0
          this._pictureMaterial.alphaMode = 2
          this._pictureMaterial.transparencyMode = 2
          this._pictureMaterial.useAlphaFromAlbedoTexture = true

          this._mainPictureTexture = this._pictureMaterial.albedoTexture as Texture
          freeze = false
        }

        /** фон у картины **/
        if (material.name === 'glass_opaque2') {
          this._pictureScreenMaterial = material
          this._pictureScreenMaterial.alphaMode = Engine.ALPHA_COMBINE
          this._pictureScreenMaterial.transparencyMode = Material.MATERIAL_ALPHABLEND
          this._pictureScreenMaterial.alpha = 0
          this._pictureScreenMaterial.roughness = 0
          this._pictureScreenMaterial.metallic = 0

          this._pictureScreenMaterial.specularIntensity = 0
          this._pictureScreenMaterial.emissiveIntensity = 0
          this._pictureScreenMaterial.emissiveColor = Color3.Black()
          this._pictureScreenMaterial.environmentIntensity = 0
          freeze = false
        }

        /** Серый фон **/
        if (material.name === 'glass_matt2.001') {
          this._screenMaterial = material
          this._screenMaterial.alpha = 0
          this._screenMaterial.alphaMode = Engine.ALPHA_COMBINE
          this._screenMaterial.transparencyMode = Material.MATERIAL_ALPHABLEND

          this._screenMaterial.emissiveIntensity = 0
          this._screenMaterial.emissiveColor = Color3.Black()
          this._screenMaterial.specularIntensity = 0
          this._screenMaterial.emissiveIntensity = 0
          this._screenMaterial.environmentIntensity = 0
          freeze = false
        }

        if (freeze) material.freeze()
      })

      assetContainer.getNodes().forEach((node) => {
        if (['Frame_appearence_animation','Frame_transform_animation', 'Plaque_animation', 'Screen_appearance'].indexOf(node.name) >= 0) {
          node.parent = this._pictureMesh as TransformNode
          (node as TransformNode).scaling = Vector3.Zero()
        }
      })

      assetContainer.meshes.forEach((mesh, index) => {
        mesh.alwaysSelectAsActiveMesh = true
        if (!mesh.parent) mesh.parent = this._pictureMesh

        if (mesh.name.indexOf('Prizm') >= 0) {

          mesh.scaling = Vector3.Zero()
          this._prizmesList.push(mesh as Mesh)
          this._prizmesPositions.push(mesh.position.clone())
          mesh.position = mesh.position.add(Vector3.Backward().multiplyByFloats(20,20,20))

          const amount = 0.005 * ( Math.abs(mesh.position.x) +  Math.abs(mesh.position.y)) * (index % 2 === 0 ? 1 : -1)
          this._prizmesRotationAmount.push(amount)
          // mesh.position.x = 0
          // mesh.position.y = 0
        }

        if (mesh.name === 'Paint') {
          this._flyingFrames = new FlyingFrames({
            sceneController: this.sceneController,
            parentMesh: mesh as Mesh
          })

          this._flyingFrames.addEventListener(FlyingFramesEvents.AnimationComplete, this._onFlyFramesAnimationComplete)

          this._pictureDetails = new PictureDetails({
            sceneController: this.sceneController,
            parentMesh: mesh as Mesh
          })

          this._letterModel = new LetterModel({
            sceneController: this.sceneController,
            parentMesh: mesh as Mesh
          })

          this._letterModel.addEventListener(LetterModelEvents.AnimationComplete, this._onLetterAnimationComplete)
        }
      })

      this._pictureMesh.parent = this.mesh

      this._pictureMesh.scaling = Vector3.Zero()
      this.dispatchEvent(new Event(PictureModelEvents.PictureModelLoaded))

      // setTimeout(() => {
      //   const picSceneController:ArPictureSceneController = this.sceneController as ArPictureSceneController
      //   picSceneController.startOptimization()
      // }, 1000)
      //})
    })
  }

  public setupOnSurface() : void {
    // this._updateModelProps()
    this._isSetuped = true

    // this.scene.freezeActiveMeshes()
    // this.scene.autoClear = false

    // this.assetContainer.meshes.forEach(mesh => {
    //   if (mesh.name.indexOf('Prizm') < 0) {
    //     mesh.freezeWorldMatrix()
    //   }
    // })

    this._soundPlayer
      .play(this._pictureMesh.position.clone(), PictureAnimations.Start)
      .then(() => {
      this._animation = PictureAnimations.Start
      this.dispatchEvent(new Event(PictureModelEvents.SoundStarted))
    })

    // setTimeout(() => {
    //   this._animationsGroups.forEach((group) => group.play(false))
    //   setTimeout(() => {
    //     this.mesh!.getChildMeshes().forEach((mesh) => {
    //       mesh.isPickable = false
    //       if (mesh.name.indexOf('High_tech_frame') >= 0) {
    //         mesh.visibility = 0
    //       }
    //     })
    //   }, 4000)
    // }, 1000)
  }

  private _updateModelProps() : void {
    if (this.mesh) {
      // this.scene.materials.forEach(mat => {
      //   if (mat.name === 'glass_matt') {
      //     mat.alpha = 0
      //   }
      //
      //   if (mat.name === 'glass_opaque_2') {
      //     (mat as PBRMaterial).emissiveColor = Color3.White()
      //   }
      // })
    }
  }

  public playAnimation(animation: PictureAnimations) : void {
    if (animation === PictureAnimations.Details) {
      this._pictureMaterial.alpha = 0
    }
    this._animation = animation
  }

  public playFrameSound(animation: PictureAnimations) : void {
    this._soundPlayer.play(null, animation)
  }
}
