
import {prop, Vue} from "vue-class-component"

class ProgressBarProps {
  currentIndex = prop({
    required: true,
    type: Number,
    default: 0
  })

  count = prop({
    required: true,
    type: Number,
    default: 0
  })

  visible = prop({
    required: true,
    type: Boolean,
    default: true
  })
}

class BarItem {
  public id: number

  constructor({id} : {id: number}) {
    this.id = id
  }
}

export default class ProgressBar extends Vue.with(ProgressBarProps) {
  public currentIndex!: number
  public count!: number
  public visible!: boolean

  public barItems: BarItem[] = []

  public mounted() : void {
    for (let i = 0 ; i < this.count; i++) {
      this.barItems.push(new BarItem({
        id: i
      }))
    }
  }
}
