
import {Options, prop, Vue, WithDefault} from "vue-class-component";

class FinalScreenProps {
  public visible = false
}

@Options({
  watch: {
    visible (val) {
      this.visibleIndex = 0
      if (val) {

        setTimeout(() => {
          this.showContent = true
          // this.interval = setInterval(() => {
          //   this.visibleIndex ++
          // }, 1000)
        }, 21000)

        setTimeout(() => {
          this.visibleIndex = 1
        }, 25000)

        setTimeout(() => {
          this.visibleIndex = 2
        }, 27000)

        setTimeout(() => {
          this.visibleIndex = 3
        }, 33000)

        setTimeout(() => {
          this.visibleIndex = 4
        }, 36000)

      } else {
        clearInterval(this.interval)
      }
    }
  }
})
export default class FinalScreen extends Vue.with(FinalScreenProps) {
  public showContent = false
  public visible!: boolean
  public aboutText = 'ИСКУССТВО ВДОХНОВЛЯЕТ ТЕХНОЛОГИИ.<br/>ТЕХНОЛОГИИ ДОПОЛНЯЮТ ИСКУССТВО'
  public dedicatedText = 'ПОСВЯЩАЕТСЯ ПРОЕКТУ<br/>РЕСТАВРАЦИИ КАРТИНЫ<br/>ВАН ГОГА<br/>“КРАСНЫЕ ВИНОГРАДНИКИ В АРЛЕ”'

  public visibleIndex = 0
  public interval: number | null = null

}
