class ApplicationConfig {
  private static _instance:ApplicationConfig
  public static getInstance() : ApplicationConfig {
    if (!this._instance) this._instance = new ApplicationConfig()
    return this._instance
  }

  public readonly webxrEnabled = true
}

export default ApplicationConfig.getInstance()
