import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {Mesh, MeshBuilder, StandardMaterial} from "@babylonjs/core";
// import {GridMaterial} from "@babylonjs/materials";

export default class GroundModel extends SceneItem {
  private _mesh!:Mesh
  private _material!:StandardMaterial

  constructor(props: SceneItemArgs) {
    super(props)

    this._createModel()
  }

  private _createModel() : void {
    if (this._mesh) this._mesh.dispose()

    this._mesh = MeshBuilder.CreateGround('ground', {
      width: 100,
      height: 100
    }, this.scene)

    this._material = new StandardMaterial('ground material', this.scene)
    this._material.alpha = 0
    this._mesh.material = this._material

    // const ratio = 0.05
    // const groundMaterial : GridMaterial = new GridMaterial("groundMaterial", this.scene)
    // groundMaterial.majorUnitFrequency = (100 / ratio) * 0.01;
    // groundMaterial.minorUnitVisibility = 0.3;
    // groundMaterial.gridRatio = ratio;
    // groundMaterial.backFaceCulling = false;
    // groundMaterial.mainColor = Color3.White()
    // groundMaterial.lineColor = Color3.FromHexString('#FF9EE3')
    // groundMaterial.opacity = 0.5;
    // groundMaterial.wireframe = false;
    // groundMaterial.fogEnabled = true
    //
    // this._material = groundMaterial
    // this._material.alpha = 0
    // this._mesh.material = this._material
  }
}
