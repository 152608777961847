import {Color3, Color4, CubeTexture, DefaultRenderingPipeline, Engine, GlowLayer, Scene} from "@babylonjs/core"
import CameraController from "./SceneController/CameraController.class"

class SceneController extends EventTarget {
  private _canvas: HTMLCanvasElement
  private _engine!: Engine
  private _scene!: Scene

  private _cameraController!: CameraController
  private _renderingPipeline!: DefaultRenderingPipeline

  private _glowLayer!: GlowLayer

  get cameraController() : CameraController {
    return this._cameraController
  }

  get engine() : Engine {
    return this._engine
  }

  get scene() : Scene {
    return this._scene
  }

  get canvas() : HTMLCanvasElement {
    return this._canvas
  }

  get glowLayer() : GlowLayer {
    return  this._glowLayer
  }

  constructor({canvas}: {canvas: HTMLCanvasElement}) {
    super()
    this._canvas = canvas
  }

  _onWindowResize = (): void => {
    setTimeout(() => {
      this._engine.resize()
    }, 0)
  }

  public initEngine(): void {
    this._engine = new Engine(this._canvas, true)
    this._engine.setHardwareScalingLevel(1)
    this._scene = new Scene(this._engine)
    this._scene.ambientColor = Color3.White()
    this._scene.clearColor = new Color4(0.,0.,0.,1)

    const hdrTexture: CubeTexture = CubeTexture.CreateFromPrefilteredData("/env/autoshop.env", this._scene)
    this._scene.environmentTexture = hdrTexture
    this._scene.environmentIntensity = 0.5

    this._glowLayer = new GlowLayer("glow", this.scene)

    window.addEventListener('resize', this._onWindowResize)

    this._engine.runRenderLoop(() => {
      this._scene.render()
    })
  }

  private _initRenderingPipeline() : void {
    this._renderingPipeline = new DefaultRenderingPipeline('pipeline', true, this.scene)
    this._renderingPipeline.glowLayerEnabled = true
    this._renderingPipeline.fxaaEnabled = true
  }

  public createCameraController(): void {
    this._cameraController = new CameraController({
      sceneController: this
    })
  }

  public dispose() :void {
    window.removeEventListener('resize', this._onWindowResize)
    this._engine.dispose()
  }
}

export default SceneController
