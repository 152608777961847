import {ArcRotateCamera, FreeCamera, HemisphericLight, Light, Vector3} from "@babylonjs/core"
import {SceneItem, SceneItemArgs} from "./abstract/SceneItem.class"
import ApplicationConfig from "@/classes/ApplicationConfig";

class CameraController extends SceneItem {
  private _camera!: FreeCamera | ArcRotateCamera
  private _light!: Light

  get camera(): FreeCamera | ArcRotateCamera {
    return this._camera
  }

  constructor(args: SceneItemArgs) {
    super(args)
    this._createCamera()
    this._createLight()
  }

  private _createCamera(): void {
    if (ApplicationConfig.webxrEnabled) {
      this._camera = new FreeCamera('camera', new Vector3(0, 3, 0), this.scene)
      const XR8 : any = (window as any)['XR8']
      // console.log(XR8)
      this._camera.addBehavior(XR8.Babylonjs.xrCameraBehavior(), true)
    } else {
      const camera: ArcRotateCamera = new ArcRotateCamera('camera', 0, 0, 5, Vector3.Zero(), this.scene)
      camera.position = new Vector3(0,0, -5)
      camera.target = new Vector3(0,2, 0)
      camera.minZ = 0.01
      camera.lowerRadiusLimit = 1
      camera.upperRadiusLimit = 20
      camera.wheelPrecision = 100
      camera.attachControl(this.canvas)

      this._camera = camera
    }
  }

  private _createLight(): void {
    this._light = new HemisphericLight('light', Vector3.Up().add(Vector3.Right()).add(Vector3.Forward()), this.scene)
    this._light.intensity = 2
  }
}

export default CameraController
